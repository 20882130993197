import React from 'react';
import SEO from '../../components/seo';
import MissionMuseumPage from '../../components/desktop/MissionMuseumPage/MissionMuseumPage';

const About = () => {
  return (
    <>
      <SEO title={'Cel muzeum'} />
      <MissionMuseumPage />
    </>
  );
};

export default About;
