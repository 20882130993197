import React from 'react';
import {
  ColorWrapper,
  Container,
  PageContainer,
  ImageWrapper,
  TitlePage,
} from './styles';
import {Parallax} from 'react-scroll-parallax';
import bg from './images/background.jpg';
import Gallery from '../Gallery/Gallery';

const images = [
  {src: require('./images/01.jpg'), sign: 'Pierwsze spotkanie zespołu'},
  {
    src: require('./images/02.jpg'),
    sign: 'Wizyta telewizji podczas prac digitalizacyjnych',
  },
  {src: require('./images/03.jpg'), sign: 'Digitalizacja rzeźb'},
  {src: require('./images/04.jpg'), sign: 'Przygotowanie rzeźby do skanu 3D'},
  {
    src: require('./images/05.jpg'),
    sign: 'Studio fotograficzne w pracownii Jana Szczepkowskiego',
  },
  {
    src: require('./images/06.jpg'),
    sign: 'Prelekcja na Konferencji „Kultura w 3D – 3D w kulturze"',
  },
];

const MissionMuseumPage = () => {
  return (
    <PageContainer backgroundColor={'#a3c0c0'} style={{paddingBottom: 0}}>
      <Parallax y={[20, -20]}>
        <ImageWrapper srcBg={bg} />
      </Parallax>
      <Parallax y={[8, -160]} className="second-parallax">
        <ColorWrapper padding bgColor="#525252">
          <TitlePage>CEL MUZEUM</TitlePage>
          <Container>
            <p
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '0.83rem',
                lineHeight: '1.8',
                textAlign: 'justify',
                color: '#ffffff',
              }}>
              &emsp;&emsp;Wirtualne Muzeum Jana Szczepkowskiego zaczęło
              powstawać w&nbsp;2020 r. dzięki dotacji jakie Milanowskie Centrum
              Kultury otrzymało w&nbsp;programie Ministerstwa Kultury
              i&nbsp;Dziedzictwa Narodowego “ Kultura Cyfrowa 2020.”
              <br />
              <br />
              &emsp;&emsp;Nasz cel to popularyzacja dorobku jednego
              z&nbsp;najwybitniejszych przedstawicieli sztuki II RP Jana
              Szczepkowskiego. W&nbsp;ostatnich latach rzeźbiarz stał się
              artystą zapomnianym a&nbsp;jego życiowa kolekcja dzieł, do dziś
              była zupełnie niedostępna. Postanowiliśmy stworzyć platformę do
              udostępnienia i poznania jego historii. Jednak nie tylko chodzi
              nam o&nbsp;prezentację dzieł. Chcemy naszą działalnością
              podkreślać znaczenie ochrony zabytków, chcemy pokazywać sztukę
              historyczną wykorzystując do tego nowe technologie. Wirtualna
              ekspozycja to jedynie początek zaplanowanego procesu ochrony
              dziedzictwa przed zapomnieniem. Rozwój naszej instytucji to szansa
              na przekazanie kolejnym pokoleniom wiedzy, metod i&nbsp;rozwiązań
              dotyczących promocji sztuki, digitalizacji, udostępniania,
              kreatywnych projektów.
              <br />
              <br />
              &emsp;&emsp; Zapraszamy Was do odwiedzenia naszego wirtualnego
              muzeum. Bez biletu, bez kolejki, za to z&nbsp;nieograniczonym
              czasem na poznanie dorobku Jana Szczepkowskiego.
            </p>
            <Gallery colorSign={'#ffffff'} images={images} />
          </Container>
        </ColorWrapper>
      </Parallax>
    </PageContainer>
  );
};

export default MissionMuseumPage;
